import styled from "styled-components";

export const AboutContainer = styled.section `
    background-color: #f393b9;
    padding: 3em;
    color: white;

`

// .header-about {
//     font-weight: 700;  
//     margin: 0;
//     cursor: pointer;
//   }


//   .hover-about {
//     visibility: hidden;
//     /* width: 100vw; */
//     height: 100vh;
//     z-index: 2000;
//     background-color: #f393b9 ;
//     color: #fff;
//     font-size: 2em;
//     padding: 3em;
//     position: fixed;
//     top: 0;
//     left: 0;

//   }

//   .header:hover .hover-about {
//     visibility:visible;
//   }

//   .hover-about .serif{
//     font-family: orpheuspro, serif;
//     font-weight: 600;
//     font-style: italic;

//   }

//   .hover-about p {
//     color: #fff;
//     font-weight: 700;
//     display: inline-flex;
//     align-items: baseline;
//   }